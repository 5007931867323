<script>

export default {
  props: {
    loading: {
      type: Boolean,
      default: () => false,
      required: false
    },
    days: {
      type: Number,
      default: () => 0,
      required: true
    },
    hours: {
      type: Number,
      default: () => 0,
      required: true
    },
    minutes: {
      type: Number,
      default: () => 0,
      required: true
    },
    seconds: {
      type: Number,
      default: () => 0,
      required: true
    },
    label: {
      type: String,
      default: () => 'Average Time',
      required: false
    },
    description: {
      type: String,
      default: () => 'Description',
      required: false
    }
  },
}
</script>

<template>
  <b-card no-body>
    <b-card-body>
      <div class="p-1 d-flex align-items-center">
        <img src="https://img.themesbrand.com/velzon/images/img-2.gif"
             class="img-fluid avatar-xs rounded-circle object-cover" alt=""
        />
        <div class="ms-3 flex-grow-1">
          <h5 class="fs-16 mb-1 fw-bold">
            <template v-if="days > 0">
              {{ days }} days
            </template>
            <template v-if="hours > 0">
              {{ hours }} hours
            </template>
            {{ minutes }} {{ minutes > 1 ? 'minutes' : 'minute' }}
            {{ seconds }} {{ minutes > 1 ? 'seconds' : 'second' }}
          </h5>
          <p class="text-muted mb-0">
            {{ label }}
          </p>
        </div>
        <div>
          <i v-b-tooltip.hover :title="description"
             class="mdi mdi-information-outline text-primary fs-22 cursor-pointer"></i>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>