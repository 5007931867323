<script>
import axios from "axios";
import userStore from '@/state/store'
import moment from "moment/moment";
import 'moment-timezone';

moment.tz.setDefault("Asia/Tashkent"); // Set default timezone once

export default {
  props: {
    status: {
      type: [String, null],
      required: false,
      default: () => null
    },
    start_date: {
      type: [String, null],
      required: false,
      default: () => null
    },
    end_date: {
      type: [String, null],
      required: false,
      default: () => null
    },
  },
  data() {
    return {
      inquires: []
    }
  },
  computed: {
    user() {
      return userStore.state.user
    },
  },
  methods: {
    async getInquires() {
      try {
        let params = {
          manager_id: this.user.id,
        }

        if (this.start_date) {
          params.date_from = this.start_date
        }

        if (this.end_date) {
          params.date_to = this.end_date
        }

        let response = await axios.get('/inquiry/statistic/kpi/detail/', {
          params: params
        })

        this.inquires = response.data.results || []
        this.inquires = this.inquires.map(i => {
          let inquiry = {...i}
          if (i.status === 'quoted') {
            inquiry.possibleCompletion = this.getInquiryPossibleCompletionStatus(i.quoted_at)
          }
          return inquiry
        })
      } catch {
        alert("error")
      }
    },
    cleanText(html) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = html;
      return tempElement.textContent || tempElement.innerText;
    },
    formatDate(date) {
      return moment(date).format('lll')
    },
    durationInDays(durationInSeconds) {
      const duration = moment.duration(durationInSeconds, 'seconds');

      const totalHours = duration.asHours();
      const days = Math.floor(duration.asDays());

      let daysDescription;

      if (totalHours < 48) {
        daysDescription = "within 2 days";
      } else {
        daysDescription = `after ${days} days`;
      }

      return daysDescription
    },
    timeAgo(startDate) {
      const date = moment(startDate); // Get the current time
      return moment(date).fromNow();
    },
    formatDuration(durationInSeconds) {
      const duration = moment.duration(durationInSeconds, 'seconds');

      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      let formattedDuration = '';

      if (days > 0) {
        formattedDuration += `${days} days `;
      }

      if (hours > 0) {
        formattedDuration += `${hours} hours `;
      }

      formattedDuration += `${minutes} mins ${seconds} secs`;

      return formattedDuration.trim(); // Trim any extra spaces
    },
    getInquiryPossibleCompletionStatus(dateQuoted) {
      const now = new Date();
      const quotedDate = new Date(dateQuoted);

      // Calculate the difference in milliseconds
      const diffInMilliseconds = now - quotedDate;

      // Convert difference to hours
      const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

      // Determine the status based on the difference in hours
      if (diffInHours < 120) { // less than 5 days (5 days = 120 hours)
        const hoursRemaining = Math.ceil(120 - diffInHours);
        return {
          label: 'Completed within',
          hoursRemaining: hoursRemaining,
          grade: 'A'
        }
      } else if (diffInHours >= 120 && diffInHours < 168) { // between 5 days (120 hours) and 7 days (168 hours)
        const hoursRemaining = Math.ceil(168 - diffInHours);
        return {
          label: 'Completed within',
          hoursRemaining: hoursRemaining,
          grade: 'B'
        }
      } else {
        return {
          label: 'AutoFailed.',
          hoursRemaining: 0,
          grade: 'C'
        }
      }
    }
  },
  mounted() {
    this.getInquires()
  }
}
</script>

<template>
  <div class="table-responsive">
    <!-- Bordered Tables -->
    <table class="table table-bordered table-nowrap">
      <thead>
      <tr>
        <th class="text-center" scope="col">#</th>
        <th scope="col">Inquiry Message</th>
        <th scope="col">Date Created</th>
        <th scope="col">
          <div class="d-flex align-items-center gap-2">
            <h6 class="mb-0 text-warning fw-medium">Pending</h6>
            <i class="mdi mdi-arrow-right"></i>
            <h6 class="mb-0 text-secondary fw-medium">Quoted</h6>
          </div>
        </th>
        <th scope="col">
          <div class="d-flex align-items-center gap-2">
            <h6 class="mb-0 text-secondary fw-medium">Quoted</h6>
            <i class="mdi mdi-arrow-right"></i>
            <div class="d-flex align-items-center">
              <h6 class="mb-0 text-success fw-medium">Success</h6>
              <i class="mdi mdi-slash-forward text-muted"></i>
              <h6 class="mb-0 text-danger fw-medium">Fail</h6>
            </div>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(inquiry, index) in inquires" :key="`inquiry_${inquiry}`">
        <tr class="align-middle">
          <th class="text-center" scope="row">{{ index + 1 }}</th>
          <td style="max-width: 75px;">
            <p class="mb-0 text-truncate pe-2"
               v-b-tooltip.hover :title="cleanText(inquiry.text)"
               v-html="cleanText(inquiry.text)"></p>
          </td>
          <td style="max-width: 150px;">
            <small class="d-block text-muted">{{ timeAgo(inquiry.created) }}</small>
            <span>{{ formatDate(inquiry.created) }}</span>
          </td>
          <td>
            <div v-if="inquiry.status === 'pending'" class="d-flex" v-b-tooltip.hover
                 :title="`Inquiry is still pending since ${formatDate(inquiry.created)}`">
              <div class="flex-shrink-0 avatar-xs">
                <div class="avatar-title rounded-circle material-shadow bg-soft-warning text-warning">
                  <i class="mdi mdi-clock-outline"></i>
                </div>
              </div>
              <div class="flex-grow-1 ms-3">
                <h6 class="mb-1 lh-base text-uppercase">
                  <span class="badge me-1 bg-soft-warning text-warning">PENDING</span>
                </h6>
                <small class="mb-0 text-muted"
                >
                  Since {{ formatDate(inquiry.created) }}
                </small>
              </div>
            </div>
            <div v-else class="d-flex">
              <div class="flex-shrink-0 avatar-xs">
                <div v-b-tooltip.hover
                     :title="`Response-Time KPI grade is '${inquiry.quote_grade}' because quoted ${durationInDays(inquiry.quote_time)}`"
                     class="avatar-title rounded-circle material-shadow" :class="{
                  'bg-soft-success text-success': inquiry.quote_grade === 'A',
                  'bg-soft-warning text-warning': inquiry.quote_grade === 'B',
                  'bg-soft-danger text-danger': inquiry.quote_grade === 'C',
                }">
                  {{ inquiry.quote_grade }}
                </div>
              </div>
              <div class="flex-grow-1 ms-3">
                <h6 class="mb-1 lh-base text-uppercase">
                  <span class="badge me-1 bg-soft-secondary text-secondary me-2">QUOTED</span>
                  <span v-b-tooltip.hover :title="`Time taken to update the inquiry status from 'pending' to 'quoted'`">
                    {{
                      formatDuration(inquiry.quote_time)
                    }}</span>
                </h6>
                <small v-b-tooltip.hover title="Date and time when the inquiry was marked as Quoted"
                       class="mb-0 text-muted">
                  {{ formatDate(inquiry.quoted_at) }} ({{ timeAgo(inquiry.quoted_at) }})
                </small>
              </div>
            </div>
          </td>
          <td>
            <div v-if="inquiry.status === 'pending'" class="d-flex" v-b-tooltip.hover
                 :title="`Inquiry is still pending since ${formatDate(inquiry.created)}`">
              <div class="flex-shrink-0 avatar-xs">
                <div class="avatar-title rounded-circle material-shadow bg-soft-warning text-warning">
                  <i class="mdi mdi-clock-outline"></i>
                </div>
              </div>
              <div class="flex-grow-1 ms-3">
                <h6 class="mb-1 lh-base text-uppercase">
                  <span class="badge me-1 bg-soft-warning text-warning">PENDING</span>
                </h6>
                <small class="mb-0 text-muted"
                >
                  Since {{ formatDate(inquiry.created) }}
                </small>
              </div>
            </div>
            <div v-else-if="inquiry.status === 'quoted'">
              <div v-if="inquiry.possibleCompletion.grade === 'A'">
                <span class="d-block">Complete within <b>{{
                    inquiry.possibleCompletion.hoursRemaining
                  }} hours</b> </span>
                <span>and get <span class="badge badge-soft-success text-success fw-medium">A</span></span> from
                Follow-up KPI
              </div>
              <div v-if="inquiry.possibleCompletion.grade === 'B'">
                <span class="d-block">Complete within <b>{{
                    inquiry.possibleCompletion.hoursRemaining
                  }} hours</b> </span>
                <span>and get <span class="badge badge-soft-warning text-warning fw-medium">B</span></span> from
                Follow-up KPI
              </div>
              <span v-else-if="inquiry.possibleCompletion.grade === 'C'">
                AutoFailed
              </span>
            </div>
            <div v-else-if="['success', 'fail'].includes(inquiry.status)" class="d-flex">
              <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                <div v-b-tooltip.hover
                     :title="`Follow-up KPI grade is '${inquiry.completion_grade}' because completed ${durationInDays(inquiry.resolution_time)}`"
                     class="avatar-title rounded-circle material-shadow" :class="{
                  'bg-soft-success text-success': inquiry.completion_grade === 'A',
                  'bg-soft-warning text-warning': inquiry.completion_grade === 'B',
                  'bg-soft-danger text-danger': inquiry.completion_grade === 'C',
                }">
                  {{ inquiry.completion_grade }}
                </div>
              </div>
              <div class="flex-grow-1 ms-3">
                <h6 class="mb-0 lh-base text-uppercase">
                    <span v-b-tooltip.hover title="Current status of inquiry"
                          class="badge me-1 mb-1" :class="{
                      'bg-soft-success text-success': inquiry.status === 'success',
                      'bg-soft-danger text-danger': inquiry.status === 'fail',
                    }">{{ inquiry.status }}</span>
                  <span v-b-tooltip.hover
                        :title="`Time taken to update the inquiry status from 'quoted' to '${inquiry.status}'`">
                    {{ formatDuration(inquiry.resolution_time) }}
                  </span>
                </h6>
                <small v-b-tooltip.hover title="Date and time when the inquiry was marked as Success"
                       v-if="inquiry.status === 'success'" class="mb-0 text-muted">
                  {{ formatDate(inquiry.success_at) }} ({{ timeAgo(inquiry.success_at) }})
                </small>
                <small v-b-tooltip.hover title="Date and time when the inquiry was marked as Fail"
                       v-if="inquiry.status === 'fail'" class="mb-0 text-muted">
                  {{ formatDate(inquiry.fail_at) }} ({{ timeAgo(inquiry.fail_at) }})
                </small>
              </div>
            </div>
          </td>
        </tr>
      </template>
      <tr v-if="inquires.length === 0">
        <th colspan="5">
          <span>No data</span>
          <span v-if="start_date"> : {{ start_date }}</span>
          <span v-if="end_date"> - {{ end_date }}</span>
        </th>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>

</style>