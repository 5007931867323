<script>
import Skeleton from "@/components/custom/skeleton.vue";
import {CountTo} from "vue3-count-to";
import ManagerInquiresByStatus from "@/views/pages/inquires/components/ManagerInquiresByStatus.vue"
import {INQUIRY_KPI} from "@/core/KPI";

export default {
  components: {
    Skeleton,
    CountTo,
    ManagerInquiresByStatus
  },
  props: {
    user_kpi_data: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    pending_inquires_count: {
      type: Number,
      required: true,
      default: () => 0
    },
    overall_kpi_percentage: {
      type: Number,
      required: true,
      default: () => 0
    },
    start_date: {
      type: [String, null],
      required: false,
      default: () => null
    },
    end_date: {
      type: [String, null],
      required: false,
      default: () => null
    },
  },
  data() {
    return {
      showLearnMoreModal: false,
      INQUIRY_KPI: INQUIRY_KPI
    }
  },
  computed: {
    total_inquiries_count() {
      if (this.user_kpi_data) {
        return this.user_kpi_data.total || 0
      }
      return 0
    },
    response_time_kpi() {
      if (this.user_kpi_data) {
        return (this.user_kpi_data.response_time || {}).target || 0
      }
      return 0
    },
    completion_kpi() {
      if (this.user_kpi_data) {
        return (this.user_kpi_data.completion || {}).target || 0
      }
      return 0
    },
    conversion_kpi() {
      if (this.user_kpi_data) {
        return (this.user_kpi_data.conversion || {}).target || 0
      }
      return 0
    },
    conversion_kpi_count() {
      if (this.user_kpi_data) {
        return (this.user_kpi_data.conversion || {}).count || 0
      }
      return 0
    },
    leads_kpi() {
      if (this.user_kpi_data) {
        return (this.user_kpi_data.leads || {}).target || 0
      }
      return 0
    },
    leads_kpi_actual() {
      if (this.user_kpi_data) {
        return (this.user_kpi_data.leads || {}).actual || 0
      }
      return 0
    }
  },
};
</script>

<template>

  <b-card no-body class="mb-0">
    <b-card-header class="border-0 d-flex flex-column">
      <div class="align-items-center d-flex mb-3">
        <b-card-title class="mb-0 flex-grow-1">KPI Overview</b-card-title>
        <slot name="date_filters"></slot>
      </div>

      <BAlert show variant="success" class="border-0 d-block mb-0" role="alert">
        <strong>
          <i class="mdi mdi-information-outline me-1"></i>
          Please note that KPI scores are assessed solely from inquiries created on or after November 1st, 2024
        </strong>
      </BAlert>

    </b-card-header>
    <b-card-body class="p-0">

      <div
          class="bg-soft-light border-top-dashed border border-start-0 border-end-0 border-bottom-dashed py-3 px-4">
        <b-row class="align-items-center">
          <b-col cols="6">
            <div class="d-flex flex-wrap gap-4 align-items-center">
              <div class="border rounded p-2">
                <h6 class="mb-2 text-truncate text-muted">KPI Percentage</h6>
                <h5 class="fs-19 mb-0">

                  <skeleton v-if="loading" class_list="py-3"
                            style="max-width: 100px"></skeleton>

                  <template v-else>
                    {{ overall_kpi_percentage }}
                    <i class="mdi mdi-percent-outline"></i>
                    <b-button class="ms-3" @click="showLearnMoreModal = true" size="sm" variant="success">
                      Learn more
                    </b-button>
                  </template>
                </h5>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="d-flex">
              <div class="d-flex justify-content-end text-end flex-wrap gap-4 ms-auto">
                <div class="border rounded p-2">
                  <h6 class="mb-2 text-truncate text-muted">{{ INQUIRY_KPI.response_time.label }}</h6>
                  <h5 class="mb-0">
                    {{ response_time_kpi }}
                    <i class="mdi mdi-percent-outline"></i>
                  </h5>
                </div>
                <div class="border rounded p-2">
                  <h6 class="mb-2 text-truncate text-muted">{{ INQUIRY_KPI.completion.label }}</h6>
                  <h5 class="mb-0">
                    {{ completion_kpi }}
                    <i class="mdi mdi-percent-outline"></i>
                  </h5>
                </div>
                <div class="border rounded p-2">
                  <h6 class="mb-2 text-truncate text-muted">{{ INQUIRY_KPI.conversion.label }}</h6>
                  <h5 class="mb-0">
                    {{ conversion_kpi }}
                    <i class="mdi mdi-percent-outline"></i>
                  </h5>
                </div>
                <div class="border rounded p-2">
                  <h6 class="mb-2 text-truncate text-muted">{{ INQUIRY_KPI.leads.label }}</h6>
                  <h5 class="mb-0">
                    {{ leads_kpi }}
                    <i class="mdi mdi-percent-outline"></i>
                  </h5>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card-body>
  </b-card>

  <BModal v-model="showLearnMoreModal" hide-footer
          title="KPI PERCENTAGE BREAKDOWN" class="v-modal-custom" size="xl">

    <div class="d-flex align-items-center gap-1 mb-3">
      <span>From <b>{{ start_date }}</b></span>
      <span>to <b>{{ end_date }}</b></span>
    </div>

    <b-row>
      <b-col md="6">
        <b-card no-body class="shadow-none border">
          <b-card-body class="position-relative">
            <div class="d-flex justify-content-between">
              <div>
                <b class="d-block text-body mb-2">
                  {{ INQUIRY_KPI.response_time.label }}
                </b>
                <p class="mb-0 text-muted">
                  {{ INQUIRY_KPI.response_time.description }}. <b>Weights {{ INQUIRY_KPI.response_time.weight }}%</b>
                </p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to :startVal='0' :endVal='response_time_kpi' :duration='5000'></count-to>
                  %
                </h2>
                <p class="mb-0 text-muted">
                  <b>Target: </b>{{ INQUIRY_KPI.response_time.target }}.
                  <i data-bs-toggle="collapse" href="#responseTimeMarking"
                     class="fw-medium ms-1 cursor-pointer text-decoration-underline">See marking criteria</i>
                </p>


              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-success rounded-circle fs-2">
                  <i class="mdi mdi-camera-timer text-success"></i>
                </span>
                </div>
              </div>
            </div>
            <b-collapse id="responseTimeMarking" class="mt-3">
              <b-list-group class="w-100">
                <BListGroupItem
                    v-for="item in INQUIRY_KPI.response_time.grades"
                    :key="`response_time_grading_item_${item.label}`"
                >
                  <div class="float-end text-end">
                    <h6 class="mb-0">{{ item.points }}</h6>
                    <small class="text-muted">Points</small>
                  </div>
                  <div class="d-flex mb-2 align-items-center">
                    <div class="flex-shrink-0 avatar-xs">
                      <div :class="['avatar-title rounded material-shadow', `bg-soft-${item.badgeClass} text-${item.badgeClass}`]">
                        <i class="mdi mdi-clock-outline"></i>
                      </div>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h5 class="list-title fs-15 mb-1">{{ item.label }}</h5>
                    </div>
                  </div>
                  <p class="list-text mb-0">
                    {{ item.description}}
                  </p>
                </BListGroupItem>
              </b-list-group>
            </b-collapse>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card no-body class="shadow-none border">
          <b-card-body class="position-relative">
            <div class="d-flex justify-content-between">
              <div>
                <b class="d-block text-body mb-2">
                  {{ INQUIRY_KPI.completion.label }}
                </b>
                <p class="mb-0 text-muted">
                  {{ INQUIRY_KPI.completion.description }}. Weights {{ INQUIRY_KPI.completion.weight }}%
                </p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to :startVal='0' :endVal='completion_kpi' :duration='5000'></count-to>
                  %
                </h2>
                <p class="mb-0 text-muted">
                  <b>Target: </b>{{ INQUIRY_KPI.completion.target }}.
                  <i data-bs-toggle="collapse" href="#completionMarking"
                     class="fw-medium ms-1 cursor-pointer text-decoration-underline">See marking criteria</i>
                </p>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-success rounded-circle fs-2">
                  <i class="mdi mdi-briefcase-edit-outline text-success"></i>
                </span>
                </div>
              </div>
            </div>
            <b-collapse id="completionMarking" class="mt-3">
              <b-list-group class="w-100">
                <BListGroupItem
                    v-for="item in INQUIRY_KPI.completion.grades"
                    :key="`response_time_grading_item_${item.label}`"
                >
                  <div class="float-end text-end">
                    <h6 class="mb-0">{{ item.points || 0 }}</h6>
                    <small class="text-muted">Points</small>
                  </div>
                  <div class="d-flex mb-2 align-items-center">
                    <div class="flex-shrink-0 avatar-xs">
                      <div :class="['avatar-title rounded material-shadow', `bg-soft-${item.badgeClass} text-${item.badgeClass}`]">
                        <i class="mdi mdi-clock-outline"></i>
                      </div>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h5 class="list-title fs-15 mb-1">{{ item.label }}</h5>
                    </div>
                  </div>
                  <p class="list-text mb-0">
                    {{ item.description}}
                  </p>
                </BListGroupItem>
              </b-list-group>
            </b-collapse>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card no-body class="shadow-none border">
          <b-card-body class="position-relative">
            <div class="d-flex justify-content-between">
              <div>
                <b class="text-body mb-2 d-block">{{ INQUIRY_KPI.conversion.label }}</b>
                <p class="mb-0 text-muted">
                  {{ INQUIRY_KPI.conversion.description }}. Weights {{ INQUIRY_KPI.conversion.weight }}%
                </p>
                <div>
                  <h2 class="mt-4 ff-secondary fw-semibold">
                    <count-to :startVal='0' :endVal='conversion_kpi' :duration='5000'></count-to>
                    %
                  </h2>
                </div>
                <p class="mb-0 text-muted">
                  <b>Target: </b>{{ INQUIRY_KPI.conversion.target }}
                </p>
                <!--                <div class="mb-0 text-muted d-flex align-items-center gap-1">-->
                <!--                  <span>({{ conversion_kpi_count }}</span>-->
                <!--                  <i class="mdi mdi-slash-forward"></i>-->
                <!--                  <span>{{ total_inquiries_count }})</span>-->
                <!--                  <i class="mdi mdi-multiplication"></i>-->
                <!--                  <span>100</span>-->
                <!--                  <i class="mdi mdi-approximately-equal"></i>-->
                <!--                  <span>-->
                <!--                    {{ ((conversion_kpi_count / total_inquiries_count) * 100).toFixed(1) }}-->
                <!--                    <i class="mdi mdi-percent-outline"></i>-->
                <!--                  </span>-->
                <!--                </div>-->
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-success rounded-circle fs-2">
                  <i class="mdi mdi-check-decagram text-success"></i>
                </span>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card no-body class="shadow-none border">
          <b-card-body class="position-relative">
            <div class="d-flex justify-content-between">
              <div>
                <b class="text-body mb-2 d-block">{{ INQUIRY_KPI.leads.label }}</b>
                <p class="mb-0 text-muted">
                  {{ INQUIRY_KPI.leads.description }}. Weights {{ INQUIRY_KPI.leads.weight }}%
                </p>
                <div>
                  <h2 class="mt-4 ff-secondary fw-semibold">
                    <count-to :startVal='0' :endVal='leads_kpi' :duration='5000'></count-to>
                    %
                  </h2>
                </div>
                <p class="mb-0 text-muted">
                  <b>Target: </b>{{ INQUIRY_KPI.leads.target }}
                </p>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-success rounded-circle fs-2">
                  <i class="mdi mdi-account-plus-outline text-success"></i>
                </span>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12">
        <ManagerInquiresByStatus
            :start_date="start_date"
            :end_date="end_date"
            v-if="showLearnMoreModal"/>
      </b-col>
    </b-row>

  </BModal>

</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=DynaPuff:wght@400..700&display=swap');

.dynapuff-font {
  font-family: "DynaPuff", system-ui;
  font-optical-sizing: auto;
  font-weight: < weight >;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}


</style>