<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";
import userStore from '@/state/store'

export default {
  name: "EditInquiry",
  emits: ['onInquiryUpdated'],
  data() {
    return {
      showModal: false,
      inquiry: null,
      editor: ClassicEditor,
      sender_select: {
        options: [],
        searchable: true,
        placeholder: 'Select sender'
      },
      sales_manager_select: {
        options: [],
        searchable: true,
        placeholder: 'Select manager'
      },
      sender_id: null,
      sales_manager_id: null,
      is_saving: false
    }
  },
  computed: {
    user() {
      return userStore.state.user
    }
  },
  components: {
    ckeditor: CKEditor.component,
    Multiselect
  },
  methods: {
    openModal(inquiry) {
      const {sender, sales_manager} = inquiry;

      this.inquiry = inquiry;
      this.sender_id = sender?.id || null;
      this.sales_manager_id = sales_manager?.id || null;

      this.sender_select.options = sender
          ? [{value: sender.id, label: sender.username}]
          : [];

      this.sales_manager_select.options = sales_manager
          ? [{value: sales_manager.id, label: sales_manager.username}]
          : [];

      this.showModal = true;
    },
    async saveInquiry() {
      let Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
      this.is_saving = true;
      try {
        await axios.put(`/inquiry/update_with_notification/${this.inquiry.id}/`, {
          sender_id: this.sender_id,
          text: this.inquiry.text,
          description: this.inquiry.description,
          status: this.inquiry.status,
          sales_manager_id: this.sales_manager_id,
          client: this.inquiry.client,
          is_new_customer: this.inquiry.is_new_customer
        })
        this.$emit('onInquiryUpdated');
        this.is_saving = false;
        this.showModal = false;
        await Toast.fire({
          icon: "success",
          title: "Inquiry updated successfully",
        });
      } catch {
        this.is_saving = false;
        await Toast.fire({
          icon: "error",
          title: "Error updating inquiry",
          text: 'Please check if you have filled all fields'
        });
      }
    },
    async asyncFindUsers(query) {
      if (query.trim().length <= 0) return;
      try {
        let response = await axios.get("/user/", {
          params: {
            username: query.trim(),
          },
        });
        return response.data.results.map((item) => {
          return {
            value: item.id,
            label: item.username,
          };
        });
      } catch {
        return []
      }
    },
    async asyncFindSender(query) {
      if (this.user.role !== 'admin') return;
      this.sender_select.options = await this.asyncFindUsers(query)
    },
    async asyncFindSalesManagers(query) {
      this.sales_manager_select.options = await this.asyncFindUsers(query)
    }
  },
}
</script>

<template>
  <b-modal v-model="showModal" title="Inquiry Message" centered hide-footer size="lg">
    <b-row v-if="inquiry" gutter-y="3">
      <b-col sm="12">
        <label class="form-label">Inquiry Message</label>
        <ckeditor v-model="inquiry.text" :editor="editor"></ckeditor>
      </b-col>
      <b-col sm="12">
        <label class="form-label">Comment</label>
        <ckeditor v-model="inquiry.description" :editor="editor"></ckeditor>
      </b-col>
      <b-col sm="12">
        <label class="form-label">Customer</label>
        <input v-model="inquiry.client" class="form-control" placeholder="Type client name">
      </b-col>

      <b-col sm="12">
        <div class="form-check form-check-success">
          <input v-model="inquiry.is_new_customer" class="form-check-input" type="checkbox">
          <label @click="inquiry.is_new_customer = !inquiry.is_new_customer" class="form-check-label">
            Is a new Customer
          </label>
        </div>
        <div v-if="inquiry.is_new_customer" class="mt-2">
          <small class="text-body">
            <span class="text-danger">Reminder: </span> Selecting this option boosts your Lead Generation KPI score.
            Be sure the customer is new. If it is later determined that the customer already exists,
            the bonus associated with this KPI will not be awarded.
          </small>
        </div>
      </b-col>

      <b-col lg="6">
        <label class="form-label">Sender</label>
        <Multiselect v-model="sender_id"
                     v-bind="sender_select"
                     @search-change="asyncFindSender"
                     :disabled="user.role !== 'admin'"
        />
      </b-col>
      <b-col lg="6">
        <label class="form-label">Sales Manager</label>
        <Multiselect v-model="sales_manager_id"
                     v-bind="sales_manager_select"
                     @search-change="asyncFindSalesManagers"
        />
      </b-col>
    </b-row>
    <div class="mt-4">
      <b-button @click="saveInquiry()" type="submit" :disabled="is_saving"
                variant="success" size="lg" class="w-100">
        {{ is_saving ? 'Saving...' : 'Save' }}
      </b-button>
    </div>
  </b-modal>
</template>

<style scoped>

</style>