<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "InquiryToFail",
  emits: ['inquiry-marked-as-fail'],
  data() {
    return {
      showModal: false,
      inquiry: null,
      fail_reason: '',
      fail_description: ''
    }
  },
  computed: {
    disable_form() {
      if (this.fail_reason.trim().length === 0) {
        return true
      } else {
        if (this.fail_reason === 'other') {
          return this.fail_description.trim().length < 4
        }
        return false
      }
    }
  },
  methods: {
    async openModal(inquiry) {
      if (inquiry) {
        this.inquiry = inquiry;
        this.showModal = true;
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Inquiry not found!',
        });
      }
    },
    async failInquiry() {
      if (this.disable_form) return
      try {
        await axios.put(`/inquiry/update_with_notification/${this.inquiry.id}/`, {
          sender_id: this.inquiry.sender.id,
          text: this.inquiry.text,
          description: `<h3>${this.fail_reason === 'other' ? this.fail_description : this.fail_reason.split('_').join(' ')}</h3><br><br>` + this.inquiry.description,
          status: 'fail',
          sales_manager_id: this.inquiry.sales_manager ? this.inquiry.sales_manager.id : null,
          client: this.inquiry.client
        })
        this.showModal = false
        this.$emit('inquiry-marked-as-fail')
      } catch {
        alert("error")
      }
    },
    closeModal() {
      this.showModal = false;
    },
  },
}
</script>

<template>
  <b-modal v-model="showModal" centered hide-footer>

    <template v-slot:title>
      Mark Inquiry as <b class="text-danger">FAIL</b>
    </template>


    <label class="form-label">Reason for Failure</label>
    <BButton-group class="w-100 mb-3" role="group" aria-label="Fail reason selection">
      <input @click="fail_reason = 'high_rates'" type="radio" class="btn-check" name="failReason"
             id="fail-reason-expensive" value="high_rates">
      <label class="btn btn-outline-danger" for="fail-reason-expensive">
        <i class="bx bx-dollar fs-14 align-middle"></i>
        High Rates
      </label>

      <input @click="fail_reason = 'cargo_not_ready'" type="radio" class="btn-check" name="failReason"
             id="fail-reason-unready" value="cargo_not_ready">
      <label class="btn btn-outline-danger" for="fail-reason-unready">
        <i class="bx bxl-dropbox fs-14 align-middle"></i>
        Cargo Not Ready
      </label>

      <input @click="fail_reason = 'other'" type="radio" class="btn-check" name="failReason" id="fail-reason-other"
             value="other">
      <label class="btn btn-outline-danger" for="fail-reason-other">
        Other ...
      </label>
    </BButton-group>

    <div v-if="fail_reason === 'other'" class="mb-3">
      <label class="form-label">Describe why you are marking as FAIL</label>
      <textarea v-model="fail_description" class="form-control" placeholder="Describe in details" rows="4"></textarea>
    </div>

    <b-button @click="failInquiry()" variant="danger" class="w-100" :disabled="disable_form">
      Mark as FAIL
    </b-button>

  </b-modal>
</template>

<style scoped>

</style>