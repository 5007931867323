const INQUIRY_KPI = {
    response_time: {
        label: "Response Time KPI",
        description: "The performance percentage based on how quickly inquiries are marked as quoted after being created",
        target: "To quote 100% of inquiries within 48 hours",
        weight: 20,
        grades: [
            {
                label: 'A',
                description: 'Quoted within 48 hours. Inquiries in this timeframe are worth 3 points.',
                badgeClass: 'success',
                points: 3
            },
            {
                label: 'B',
                description: 'Quoted between 48 and 72 hours. Inquiries in this timeframe are worth 2 points.',
                badgeClass: 'warning',
                points: 2
            },
            {
                label: 'C',
                description: 'Quoted after 72 hours. Inquiries in this timeframe are subject to a 1-point reduction.',
                badgeClass: 'danger',
                points: -1
            }
        ]
    },
    completion: {
        label: "Follow-up KPI",
        description: "The performance percentage based on how quickly inquiries are marked as success or fail after being quoted",
        target: "To complete 100% of inquiries within 5 days after quoting",
        weight: 10,
        grades: [
            {
                label: 'A',
                description: 'Completed within 5 days. Inquiries in this timeframe are worth 3 points.',
                badgeClass: 'success',
                points: 3
            },
            {
                label: 'B',
                description: 'Completed between 6 and 7 days. Inquiries in this timeframe are worth 2 points.',
                badgeClass: 'warning',
                points: 2
            },
            {
                label: 'C',
                description: 'Completed after 7 days. Inquiries in this timeframe are subject to a 1-point reduction.',
                badgeClass: 'danger',
                points: -1
            }
        ]
    },
    conversion: {
        label: "Conversion KPI",
        description: "Shows the percentage of successful inquiries compared to the total inquiries received (excluding pendings)",
        target: "To successfully complete at least 10% of total inquiries",
        weight: 30,
    },
    leads: {
        label: "Leads KPI",
        description: "Shows the number of inquiries generated from new clients, reflecting the growth of our client base",
        target: "To create 10 inquiries with new different clients",
        weight: 40,
    }
}

export {
    INQUIRY_KPI
}