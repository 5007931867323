<script>
import userStore from '@/state/store'
import axios from "axios";

function getChartColorsArray(statuses) {
  let colors = []

  statuses.forEach(item => {
    if (item.status.toLowerCase() === 'pending') {
      colors.push('--vz-warning')
    } else if (item.status.toLowerCase() === 'quoted') {
      colors.push('--vz-secondary')
    } else if (item.status.toLowerCase() === 'success') {
      colors.push('--vz-success')
    } else if (item.status.toLowerCase() === 'fail') {
      colors.push('--vz-danger')
    }
  })

  colors = JSON.parse(JSON.stringify(colors));

  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
      if (color) {
        color = color.replace(" ", "");
        return color;
      } else return newValue;
    } else {
      var val = value.split(',');
      if (val.length == 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
}

export default {
  computed: {
    chart() {
      return {
        series: this.statuses.map(i => i.count),
        chartOptions: {
          labels: this.statuses.map(i => i.status),
          chart: {
            type: "donut",
            height: 224,
          },
          plotOptions: {
            pie: {
              size: 100,
              offsetX: 0,
              offsetY: 0,
              donut: {
                size: "70%",
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: "18px",
                    offsetY: -5,
                  },
                  value: {
                    show: true,
                    fontSize: "20px",
                    color: "#343a40",
                    fontWeight: 500,
                    offsetY: 5,
                    formatter: function (val) {
                      return val;
                    },
                  },
                  total: {
                    show: true,
                    fontSize: "13px",
                    label: "Total Inquires",
                    color: "#9599ad",
                    fontWeight: 500,
                    formatter: function (w) {
                      return (
                          w.globals.seriesTotals.reduce(function (a, b) {
                            return a + b;
                          }, 0)
                      );
                    },
                  },
                },
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return value + ` ${value > 1 ? 'Inquires' : 'Inquiry'}`;
              },
            },
          },
          stroke: {
            lineCap: "round",
            width: 2,
          },
          colors: getChartColorsArray(this.statuses),
        },
      };
    },
    user() {
      return userStore.state.user
    },
    dates() {
      return (this.date_start || '1') + (this.date_to || '2')
    }
  },
  data() {
    return {
      loading: false,
      statuses: []
    }
  },
  props: {
    date_start: {
      type: [String, null],
      default: () => null,
      required: false
    },
    date_to: {
      type: [String, null],
      default: () => null,
      required: false
    },
  },
  methods: {
    async getStatistics() {
      try {
        this.loading = true
        let response = await axios.get('/inquiry/statistic/', {
          params: {
            date_from: this.date_start ? this.date_start : undefined,
            date_to: this.date_to ? this.date_to : undefined,
          }
        })
        let manager = response.data.find(i => i.manager.id === this.user.id)
        this.statuses = manager ? manager.statuses : []
        this.loading = false
      } catch {
        this.statuses = []
        this.loading = false
      }
    },
  },
  watch: {
    dates() {
      this.getStatistics()
    }
  }
};
</script>

<template>
  <b-card no-body class="card-height-100">
    <b-card-header class="border-0 align-items-center d-flex pb-0">
      <b-card-title class="mb-0 flex-grow-1">Overview</b-card-title>
    </b-card-header>
    <b-card-body class="pb-0">

      <div class="d-flex align-items-center gap-1 mb-3">
        <span>From <b>{{ date_start }}</b></span>
        <span>to <b>{{ date_to }}</b></span>
      </div>

      <div v-if="loading" class="text-center py-5 my-5">
        <b-spinner variant="success"></b-spinner>
      </div>

      <template v-else>
        <apexchart v-if="chart.series.length > 0" class="apex-charts" height="224" dir="ltr" :series="chart.series"
                   :options="chart.chartOptions"></apexchart>
        <div v-else class="py-5 my-5 text-center text-muted">
          No data to show
        </div>
      </template>

      <div class="mt-3">
        <template v-for="item in statuses" :key="`status_${item.status}`">
          <div class="d-flex justify-content-between align-items-center border-bottom border-bottom-dashed py-2">
            <p class="fw-medium mb-0 text-capitalize">
              <i class="ri-checkbox-blank-circle-fill align-middle me-2" :class="{
                'text-warning': item.status.toLowerCase() === 'pending',
                'text-success': item.status.toLowerCase() === 'success',
                'text-secondary': item.status.toLowerCase() === 'quoted',
                'text-danger': item.status.toLowerCase() === 'fail',
              }"></i>
              {{ item.status }}
            </p>
            <span class="badge cursor-pointer" :class="{
              'bg-soft-warning text-warning': item.status.toLowerCase() === 'pending',
              'bg-soft-success text-success': item.status.toLowerCase() === 'success',
              'bg-soft-secondary text-secondary': item.status.toLowerCase() === 'quoted',
              'bg-soft-danger text-danger': item.status.toLowerCase() === 'fail',
            }">
              {{ item.count || 0 }}
            </span>
          </div>
        </template>
      </div>
    </b-card-body>
  </b-card>
</template>